import { fetchWithTimeoutErrorHandling } from '@/utils/http'

export class VerificationCodeService {
  static async create(
    phone: string,
    country: {value: string},
    processingFunc?: (val: boolean) => void,
    email?: string,
    fn?: string,
    ln?: string,
  ) {
    processingFunc && processingFunc(true)

    const json = await fetchWithTimeoutErrorHandling(`${process.env.NEXT_PUBLIC_BACKEND_URL}/verification-code/create`, {
      body: JSON.stringify({
        countryCode: country.value,
        phone: phone.replace(/\s/g,''),
        emailAddress: email,
        firstName: fn,
        lastName: ln
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST'
    })

    processingFunc && processingFunc(false)

    return json
  }

  static async compare(value: string, smsVerificationId: string, processingFunc?: (val: boolean) => void) {
    processingFunc && processingFunc(true)

    const json = await fetchWithTimeoutErrorHandling(`${process.env.NEXT_PUBLIC_BACKEND_URL}/verification-code/compare`, {
      body: JSON.stringify({
        id: smsVerificationId,
        code: value
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST'
    })

    processingFunc && processingFunc(false)

    return json
  }
}
