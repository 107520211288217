import { useState } from 'react'
import PhoneInput, { isPossiblePhoneNumber, formatPhoneNumber } from 'react-phone-number-input'
import { InputInvalid } from '@/components/icons/InputInvalid'
import { InputValid } from '@/components/icons/InputValid'
import { getCountryCallingCode } from '@/utils/phoneCodes'
import cn from 'classnames'

interface Props {
  onInvalid(): void,
  onValid(value: any): void,
  onCountryChange(value: any): void,
  value?: string,
  label?: string,
  error?: string,
  dark?: boolean,
  className?: string,
  country: string,
  compRef?: {current: any},
}

export const PhoneControl: React.FC<Props> = ({ onInvalid, onValid, onCountryChange, label, error = '', compRef, country, value = '', className = '', dark = true }) => {
  const [errorState, setError] = useState(error)
  const [valid, setValid] = useState(false)
  const [currentCountry, setCurrentCountry] = useState(country)
  const [currentValue, setCurrentValue] = useState(value)

  const inputClasses = cn({
    'control_phone_invalid': !!errorState || !!error,
    'control_phone_valid': valid,
    'white_control': !dark
  })

  const isValid = () => {
    if (!currentValue) return false
    if (!isPossiblePhoneNumber(currentValue)) return false

    return true
  }

  const getPhone = () => {
    //@ts-ignore
    const callingCode = getCountryCallingCode(currentCountry)

    return {
      full: currentValue,
      local: currentValue.replace(/\s/g, '').replace('+' + callingCode, '')
    }
  }

  if (compRef && compRef.current) {
    compRef.current.isValid = isValid
    compRef.current.getPhone = getPhone
  }

  const validate = (value: string, showError: boolean) => {
    //@ts-ignore
    const formattedPhone = formatPhoneNumber(value)

    setCurrentValue(value)
    setError('')
    setValid(false)

    if (!formattedPhone) {
      if (showError) {
        setError('Phone number required')
      }

      onInvalid()
      return
    }

    if (!isPossiblePhoneNumber(value)) {
      if (showError) {
        setError('Invalid phone number')
      }

      onInvalid()
      return
    }

    setValid(true)
    //@ts-ignore
    const callingCode = getCountryCallingCode(currentCountry)

    onValid({
      full: value,
      local: value.replace('+' + callingCode, '')
    })
  }

  return (
    <div className={`control_phone ${className}`}>
      {label && <label className={`control_phone_label_dark ${!dark ? 'white_control' : ''}`}>{label}</label>}
      <PhoneInput
        className={inputClasses}
        international
        countryCallingCodeEditable={false}
        //@ts-ignore
        defaultCountry={country}
        //@ts-ignore
        value={value}
        placeholder="Enter phone number"
        onBlur={(value: any) => {
          validate(value.target.value, true)
        }}
        onCountryChange={(val: any) => {
          setCurrentCountry(val)
          onCountryChange(val)
        }}
        onChange={(value: any) => {
          validate(value, false)
        }}
      />
      {valid && <InputValid className="control_phone_icon" />}
      {!!errorState && <InputInvalid className="control_phone_icon" />}
      {errorState && <span className="control_phone_error">{errorState}</span>}
    </div>
  )
}
